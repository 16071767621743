import styled from "styled-components";

export const SignInContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 380px;
`
export const Heading = styled.h2`
    margin: 10px 0px;
`

export const ButtonsContainer = styled.div`
    display: flex !important;
    justify-content: space-between; 
`

