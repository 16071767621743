import {React, Fragment, useContext } from "react";
import {NavigationContainer, NavLinks,NavLink,LogoContainer} from './navigation.styles';
import { Outlet } from "react-router-dom";
import {ReactComponent as CrwnLogo} from '../../assets/crown.svg';
import CartIcon from "../../components/cart-icon/cart-icon.component";
import CartDropdown from "../../components/cart-dropdown/cart-dropdown.component";
import { UserContext } from "../../context/user.context";
import { signOutUser } from "../../utils/firebase/firebase.utils";
import { CartContext } from "../../context/cart.context";


const Navigation = () => {
  const  {currentUser } = useContext(UserContext);
  const { isCartOpen} = useContext(CartContext)
    return (
      <Fragment>
        <NavigationContainer>
        <LogoContainer to='/'>
          <CrwnLogo className="logo" /></LogoContainer>
          <NavLinks>
            <NavLink to='/shop'>
                SHOP
            </NavLink>
            { currentUser ? (
              <NavLink as='span' onClick={signOutUser}>SIGN OUT</NavLink>
              ) : (<NavLink to='/auth'>
                SIGN IN
              </NavLink>)
            }
            <CartIcon />
          </NavLinks>
          { isCartOpen && <CartDropdown />}
        </NavigationContainer>
        <Outlet />
      </Fragment>
    )
  }

  export default Navigation;